import {
  Box,
  Button,
  Container,
  createStyles,
  Pagination,
  SimpleGrid,
  Space,
  Text,
  Title,
  useMantineTheme,
} from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { graphql, Link } from "gatsby";
import React, { useMemo, useState } from "react";
import { Helmet } from "react-helmet";
import Layout from "../../components/Layout";

const useStyles = createStyles((theme) => ({
  title: {
    fontSize: 34,
    fontWeight: 900,
    fontFamily: "Catamaran",
    fontSize: "3rem",
    color: "rgba(50, 59, 60, 0.9)",
    [theme.fn.smallerThan("sm")]: {
      fontSize: 24,
    },
  },

  description: {
    maxWidth: 600,
    margin: "auto",
    fontFamily: "Catamaran",

    "&::after": {
      content: '""',
      display: "block",
      backgroundColor: theme.fn.primaryColor(),
      width: 45,
      height: 2,
      marginTop: theme.spacing.sm,
      marginLeft: "auto",
      marginRight: "auto",
    },
    [theme.fn.largerThan("sm")]: {
      fontSize: "1.8rem",
      marginTop: ".2rem",
    },
  },
  card: {
    height: "23rem",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "flex-start",
    backgroundSize: "cover",
    backgroundPosition: "center",
    animation: "card-show-animation 0.5s ease-in-out",
  },
  sectionTitle: {
    fontSize: "3rem",
    //   fontVariant: "small-caps",
    "&::after": {
      content: '""',
      display: "block",
      backgroundColor: "#b28a4f",
      transition: "width 0.3s ease-in-out",
      width: 100,
      height: 2,
      marginBottom: theme.spacing.xs,
    },
    ".mantine-Paper-root:hover &::after": {
      width: "100%",
    },
  },
  ctaButton: {
    // display: 'inline-block',
    // padding: '15px 30px',
    fontSize: ".7rem",
    fontWeight: "bold",
    fontFamily: "Oswald",
    textDecoration: "none",
    color: "#cdcdcddd",
    // boxShadow: '0 5px 10px rgba(0, 0, 0, 0.2)',
    // backgroundColor: "rgba(131, 96, 47, 0.4)", /* white with 60% opacity */
    // backdropFilter: "blur(10px)",
    // marginTop: "20px",
    borderRadius: "0",
    letterSpacing: "2px",
    textTransform: "uppercase",

    transition: "all 0.3s ease",
    backgroundColor: "#073B4C",
    // color: "white",
    "&:hover": {
      backgroundColor: "white",
      color: "#333d",
    },
    [theme.fn.largerThan("md")]: {
      // width: '15rem',
      // marginLeft: "0.3rem",
      // marginLeft: "auto",
      // marginRight: "auto"
    },
  },

  title: {
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    fontWeight: 900,
    color: theme.white,
    lineHeight: 1.2,
    fontSize: "2rem",
    marginTop: theme.spacing.xs,
  },

  location: {
    color: theme.white,
    opacity: 0.7,
    fontWeight: 700,
    textTransform: "uppercase",
  },
}));
const randomSortArray = (arr) => {
  return arr.sort(() => Math.random() - 0.5);
};

const ProjectsPage = (props) => {
  const { classes } = useStyles();
  const [activePage, setPage] = useState((typeof window !== "undefined" && typeof sessionStorage !== "undefined" && sessionStorage.getItem('projectsPage')) ? Number.parseInt(sessionStorage.getItem('projectsPage')) : 1);
  const { title } = props.data.site.siteMetadata;
  const projects = props.data.allMarkdownRemark?.edges
    ?.map((edge) => ({
      ...edge?.node?.frontmatter,
      slug: edge?.node?.fields?.slug,
    }))
    .filter((x) => x);
  // const projects = randomSortArray([
  //     ...projects1,
  //     ...projects1,
  //     ...projects1,
  //     ...projects1,
  //     ...projects1,
  //     ...projects1,
  // ]);

  const projectsOfCurrentPage = useMemo(() => {
    return projects.slice((activePage - 1) * 6, activePage * 6);
  }, [activePage, projects, setPage]);
  const theme = useMantineTheme();
  const mobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm}px)`);

  return (
    <Layout>
      <Box mt={56} pos="relative">
        <Helmet title={title} />
        <Box
          h="100%"
          w="100%"
          top={0}
          pos={"absolute"}
          bottom={0}
          right={0}
          left={0}
          bg="url(/img/wall_background.webp)"
          style={{
            backgroundSize: "cover",
            opacity: 0.15,
            boxShadow:
              "inset 0 0 0 2000px rgba(0,0,0,.3), inset 0px -100px 100px -100px #000000",
            zIndex: -1,
          }}
        />
        <Container size={"lg"} py="xl">
          <Title
            className={classes.sectionTitle}
            order={1}
            color="rgba(50, 59, 60, 0.8)"
            ff="Oswald"
            ml="sm"
          >
            Unsere Projekte
          </Title>
          <SimpleGrid cols={mobile ? 1 : 3}>
            {projectsOfCurrentPage.map((project, index) => (
              <Card key={"projects_card" + index} {...project} />
            ))}
          </SimpleGrid>
          {projects?.length > 6 && (
            <Pagination
              page={activePage}
              onChange={(x) => {
                setPage(x);
                sessionStorage.setItem('projectsPage', x)
              }}
              mt="xl"
              total={Math.round(projects.length / 6)}
              position="center"
              withEdges
              // nextIcon={IconArrowRight}
              // previousIcon={IconArrowLeft}
              // firstIcon={IconArrowBarToLeft}
              // lastIcon={IconArrowBarToRight}
              // dotsIcon={IconGripHorizontal}
            />
          )}
        </Container>
      </Box>
    </Layout>
  );
};

function Card({ featuredImageUrl, title, location, slug, date }) {
  const { classes } = useStyles();

  return (
    <Box
      // shadow="xl"
      p="xl"
      m={10}
      // radius="md"
      sx={{
        backgroundImage: `url(${featuredImageUrl})`,
        boxShadow:
          "inset 0 0 0 2000px rgba(0,0,0,.7), 0 0px 5px rgba(0, 0, 0, 0.7)",
      }}
      className={classes.card}
    >
      <Box w="100%" style={{ filter: "brightness:(5.1)" }}>
        <Text className={classes.location} size="xs">
          {location} - {date}
        </Text>
        <Title order={3} className={classes.title}>
          {title}
        </Title>
      </Box>
      <Link to={slug}>
        <Button className={classes.ctaButton} variant="filled">
          Öffne Projekt
        </Button>
        <Space h="md" />
      </Link>
      {/* <Button mb="md" variant="filled" color="dark">
          Öffne Projekt
        </Button> */}
    </Box>
  );
}

export default ProjectsPage;

export const projectsPageQuery = graphql`
  query ProjectsPageQuery {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "project-page" } } }
      sort: { order: DESC, fields: [frontmatter___date] }
    ) {
      edges {
        node {
          excerpt(pruneLength: 400)
          id
          fields {
            slug
          }
          frontmatter {
            title
            templateKey
            date(formatString: "MM YYYY")
            featuredImageUrl
            location
          }
        }
      }
    }
  }
`;
